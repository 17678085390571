// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               v5.26.1
// source: go.chromium.org/luci/resultdb/proto/v1/instruction.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "luci.resultdb.v1";

export enum InstructionTarget {
  INSTRUCTION_TARGET_UNSPECIFIED = 0,
  /** LOCAL - For running in a local machine. */
  LOCAL = 1,
  /** REMOTE - For running remotely. */
  REMOTE = 2,
  /** PREBUILT - For prebuilt images. */
  PREBUILT = 3,
}

export function instructionTargetFromJSON(object: any): InstructionTarget {
  switch (object) {
    case 0:
    case "INSTRUCTION_TARGET_UNSPECIFIED":
      return InstructionTarget.INSTRUCTION_TARGET_UNSPECIFIED;
    case 1:
    case "LOCAL":
      return InstructionTarget.LOCAL;
    case 2:
    case "REMOTE":
      return InstructionTarget.REMOTE;
    case 3:
    case "PREBUILT":
      return InstructionTarget.PREBUILT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum InstructionTarget");
  }
}

export function instructionTargetToJSON(object: InstructionTarget): string {
  switch (object) {
    case InstructionTarget.INSTRUCTION_TARGET_UNSPECIFIED:
      return "INSTRUCTION_TARGET_UNSPECIFIED";
    case InstructionTarget.LOCAL:
      return "LOCAL";
    case InstructionTarget.REMOTE:
      return "REMOTE";
    case InstructionTarget.PREBUILT:
      return "PREBUILT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum InstructionTarget");
  }
}

export enum InstructionType {
  INSTRUCTION_TYPE_UNSPECIFIED = 0,
  /** STEP_INSTRUCTION - Instruction for step. */
  STEP_INSTRUCTION = 1,
  /** TEST_RESULT_INSTRUCTION - Instruction for test result. */
  TEST_RESULT_INSTRUCTION = 2,
}

export function instructionTypeFromJSON(object: any): InstructionType {
  switch (object) {
    case 0:
    case "INSTRUCTION_TYPE_UNSPECIFIED":
      return InstructionType.INSTRUCTION_TYPE_UNSPECIFIED;
    case 1:
    case "STEP_INSTRUCTION":
      return InstructionType.STEP_INSTRUCTION;
    case 2:
    case "TEST_RESULT_INSTRUCTION":
      return InstructionType.TEST_RESULT_INSTRUCTION;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum InstructionType");
  }
}

export function instructionTypeToJSON(object: InstructionType): string {
  switch (object) {
    case InstructionType.INSTRUCTION_TYPE_UNSPECIFIED:
      return "INSTRUCTION_TYPE_UNSPECIFIED";
    case InstructionType.STEP_INSTRUCTION:
      return "STEP_INSTRUCTION";
    case InstructionType.TEST_RESULT_INSTRUCTION:
      return "TEST_RESULT_INSTRUCTION";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum InstructionType");
  }
}

/**
 * A collection of instructions.
 * Used for step and test result instructions.
 * Instructions may mixed between step and test instructions.
 * This has a size limit of 1MB.
 */
export interface Instructions {
  readonly instructions: readonly Instruction[];
}

/**
 * Instruction is one failure reproduction instruction for a step or test result.
 * Instruction can have different targets, like "local" or "remote".
 * Instructions are stored in invocation level.
 */
export interface Instruction {
  /**
   * ID of the instruction. Required.
   * It is consumer-defined and is unique within the an invocation.
   * The tuple (invocation_id, instruction_id) can uniquely identify an instruction.
   * At this moment, we only has use cases for instruction ID for step instructions,
   * but we also require test instruction to have ID, for possible features
   * or enhancements in the future.
   * Format [a-z][a-z0-9_\-:.]{0,99}
   * Limit: 100 bytes.
   */
  readonly id: string;
  /** Either step or test instruction. */
  readonly type: InstructionType;
  /**
   * List of instruction for different targets.
   * There is at most 1 instruction per target.
   * If there is more than 1, an error will be returned.
   */
  readonly targetedInstructions: readonly TargetedInstruction[];
  /**
   * Specified the collection of test results that this instruction applies to.
   * For example, we can target all test results within a child invocation.
   * The consumer needs to make sure that any test result only has at most 1 instruction.
   * Otherwise, the behavior is undeterministic.
   * If no filter is applied, assume this applies to all test results contained
   * in this invocation and included invocations.
   * Only applicable for test instructions. This field will be ignored for step instructions.
   */
  readonly instructionFilter:
    | InstructionFilter
    | undefined;
  /**
   * This is an output only field, representing the name of the instruction.
   * Format: invocations/<invocation_id>/instructions/<instruction_id>
   * If this field is set as input, it will be ignored.
   */
  readonly name: string;
  /**
   * The descriptive, human-readable name of the instruction.
   * It will be showed in the dependency section in MILO.
   * Limit: 100 bytes.
   */
  readonly descriptiveName: string;
}

/** InstructionFilter specifies the test results that this instruction applies to. */
export interface InstructionFilter {
  readonly invocationIds?: InstructionFilterByInvocationID | undefined;
}

export interface InstructionFilterByInvocationID {
  /** Only test results contained in these invocation IDs will be selected. */
  readonly invocationIds: readonly string[];
  /**
   * Whether the check is recursive (i.e. whether it applies to test results
   * in included invocation).
   */
  readonly recursive: boolean;
}

/**
 * Instruction for specific targets.
 * Instruction for different targets may have the same or different dependency
 * and content.
 */
export interface TargetedInstruction {
  /**
   * The targets that this instruction is for, like "LOCAL", "REMOTE" or "PREBUILT".
   * A targeted instruction can only depend on another instruction with the same target.
   * For example, a "LOCAL" instruction can only depend on another "LOCAL" instruction.
   */
  readonly targets: readonly InstructionTarget[];
  /**
   * Another instruction that this instruction depends on.
   * At the moment, one instruction can have at most 1 dependency.
   * Make this repeated for forward compatibility.
   */
  readonly dependencies: readonly InstructionDependency[];
  /**
   * The content of the instruction, in markdown format.
   * Placeholders may be used and will be populated with real
   * information when displayed in the UI.
   * This will be limit to 10KB. If the content is longer than 10KB,
   * an error will be returned.
   * See go/luci-failure-reproduction-instructions-dd for details.
   */
  readonly content: string;
}

/**
 * Specifies a dependency for instruction.
 * An instruction being depended on needs to be step instruction, not test result instruction.
 * If the dependency cannot be found, or the user does not have the ACL,
 * the dependency chain will stop and Milo will not display the dependency.
 * If a dependency cycle is detected, we will stop showing dependency once we detected the cycle.
 */
export interface InstructionDependency {
  /**
   * The invocation ID of the instruction being depended on.
   * Limit: 100 bytes
   */
  readonly invocationId: string;
  /**
   * The instruction ID of the instruction being depended on.
   * (invocation_id, instruction_id) uniquely identify an invocation.
   */
  readonly instructionId: string;
}

function createBaseInstructions(): Instructions {
  return { instructions: [] };
}

export const Instructions = {
  encode(message: Instructions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.instructions) {
      Instruction.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Instructions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstructions() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.instructions.push(Instruction.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Instructions {
    return {
      instructions: globalThis.Array.isArray(object?.instructions)
        ? object.instructions.map((e: any) => Instruction.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Instructions): unknown {
    const obj: any = {};
    if (message.instructions?.length) {
      obj.instructions = message.instructions.map((e) => Instruction.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<Instructions>): Instructions {
    return Instructions.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Instructions>): Instructions {
    const message = createBaseInstructions() as any;
    message.instructions = object.instructions?.map((e) => Instruction.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInstruction(): Instruction {
  return { id: "", type: 0, targetedInstructions: [], instructionFilter: undefined, name: "", descriptiveName: "" };
}

export const Instruction = {
  encode(message: Instruction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    for (const v of message.targetedInstructions) {
      TargetedInstruction.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.instructionFilter !== undefined) {
      InstructionFilter.encode(message.instructionFilter, writer.uint32(34).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.descriptiveName !== "") {
      writer.uint32(50).string(message.descriptiveName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Instruction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstruction() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.targetedInstructions.push(TargetedInstruction.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.instructionFilter = InstructionFilter.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.descriptiveName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Instruction {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      type: isSet(object.type) ? instructionTypeFromJSON(object.type) : 0,
      targetedInstructions: globalThis.Array.isArray(object?.targetedInstructions)
        ? object.targetedInstructions.map((e: any) => TargetedInstruction.fromJSON(e))
        : [],
      instructionFilter: isSet(object.instructionFilter)
        ? InstructionFilter.fromJSON(object.instructionFilter)
        : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      descriptiveName: isSet(object.descriptiveName) ? globalThis.String(object.descriptiveName) : "",
    };
  },

  toJSON(message: Instruction): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.type !== 0) {
      obj.type = instructionTypeToJSON(message.type);
    }
    if (message.targetedInstructions?.length) {
      obj.targetedInstructions = message.targetedInstructions.map((e) => TargetedInstruction.toJSON(e));
    }
    if (message.instructionFilter !== undefined) {
      obj.instructionFilter = InstructionFilter.toJSON(message.instructionFilter);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.descriptiveName !== "") {
      obj.descriptiveName = message.descriptiveName;
    }
    return obj;
  },

  create(base?: DeepPartial<Instruction>): Instruction {
    return Instruction.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Instruction>): Instruction {
    const message = createBaseInstruction() as any;
    message.id = object.id ?? "";
    message.type = object.type ?? 0;
    message.targetedInstructions = object.targetedInstructions?.map((e) => TargetedInstruction.fromPartial(e)) || [];
    message.instructionFilter = (object.instructionFilter !== undefined && object.instructionFilter !== null)
      ? InstructionFilter.fromPartial(object.instructionFilter)
      : undefined;
    message.name = object.name ?? "";
    message.descriptiveName = object.descriptiveName ?? "";
    return message;
  },
};

function createBaseInstructionFilter(): InstructionFilter {
  return { invocationIds: undefined };
}

export const InstructionFilter = {
  encode(message: InstructionFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.invocationIds !== undefined) {
      InstructionFilterByInvocationID.encode(message.invocationIds, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstructionFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstructionFilter() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.invocationIds = InstructionFilterByInvocationID.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstructionFilter {
    return {
      invocationIds: isSet(object.invocationIds)
        ? InstructionFilterByInvocationID.fromJSON(object.invocationIds)
        : undefined,
    };
  },

  toJSON(message: InstructionFilter): unknown {
    const obj: any = {};
    if (message.invocationIds !== undefined) {
      obj.invocationIds = InstructionFilterByInvocationID.toJSON(message.invocationIds);
    }
    return obj;
  },

  create(base?: DeepPartial<InstructionFilter>): InstructionFilter {
    return InstructionFilter.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<InstructionFilter>): InstructionFilter {
    const message = createBaseInstructionFilter() as any;
    message.invocationIds = (object.invocationIds !== undefined && object.invocationIds !== null)
      ? InstructionFilterByInvocationID.fromPartial(object.invocationIds)
      : undefined;
    return message;
  },
};

function createBaseInstructionFilterByInvocationID(): InstructionFilterByInvocationID {
  return { invocationIds: [], recursive: false };
}

export const InstructionFilterByInvocationID = {
  encode(message: InstructionFilterByInvocationID, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.invocationIds) {
      writer.uint32(10).string(v!);
    }
    if (message.recursive !== false) {
      writer.uint32(16).bool(message.recursive);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstructionFilterByInvocationID {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstructionFilterByInvocationID() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.invocationIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.recursive = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstructionFilterByInvocationID {
    return {
      invocationIds: globalThis.Array.isArray(object?.invocationIds)
        ? object.invocationIds.map((e: any) => globalThis.String(e))
        : [],
      recursive: isSet(object.recursive) ? globalThis.Boolean(object.recursive) : false,
    };
  },

  toJSON(message: InstructionFilterByInvocationID): unknown {
    const obj: any = {};
    if (message.invocationIds?.length) {
      obj.invocationIds = message.invocationIds;
    }
    if (message.recursive !== false) {
      obj.recursive = message.recursive;
    }
    return obj;
  },

  create(base?: DeepPartial<InstructionFilterByInvocationID>): InstructionFilterByInvocationID {
    return InstructionFilterByInvocationID.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<InstructionFilterByInvocationID>): InstructionFilterByInvocationID {
    const message = createBaseInstructionFilterByInvocationID() as any;
    message.invocationIds = object.invocationIds?.map((e) => e) || [];
    message.recursive = object.recursive ?? false;
    return message;
  },
};

function createBaseTargetedInstruction(): TargetedInstruction {
  return { targets: [], dependencies: [], content: "" };
}

export const TargetedInstruction = {
  encode(message: TargetedInstruction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.targets) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.dependencies) {
      InstructionDependency.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.content !== "") {
      writer.uint32(26).string(message.content);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TargetedInstruction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTargetedInstruction() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.targets.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.targets.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dependencies.push(InstructionDependency.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.content = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TargetedInstruction {
    return {
      targets: globalThis.Array.isArray(object?.targets)
        ? object.targets.map((e: any) => instructionTargetFromJSON(e))
        : [],
      dependencies: globalThis.Array.isArray(object?.dependencies)
        ? object.dependencies.map((e: any) => InstructionDependency.fromJSON(e))
        : [],
      content: isSet(object.content) ? globalThis.String(object.content) : "",
    };
  },

  toJSON(message: TargetedInstruction): unknown {
    const obj: any = {};
    if (message.targets?.length) {
      obj.targets = message.targets.map((e) => instructionTargetToJSON(e));
    }
    if (message.dependencies?.length) {
      obj.dependencies = message.dependencies.map((e) => InstructionDependency.toJSON(e));
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    return obj;
  },

  create(base?: DeepPartial<TargetedInstruction>): TargetedInstruction {
    return TargetedInstruction.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TargetedInstruction>): TargetedInstruction {
    const message = createBaseTargetedInstruction() as any;
    message.targets = object.targets?.map((e) => e) || [];
    message.dependencies = object.dependencies?.map((e) => InstructionDependency.fromPartial(e)) || [];
    message.content = object.content ?? "";
    return message;
  },
};

function createBaseInstructionDependency(): InstructionDependency {
  return { invocationId: "", instructionId: "" };
}

export const InstructionDependency = {
  encode(message: InstructionDependency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.invocationId !== "") {
      writer.uint32(10).string(message.invocationId);
    }
    if (message.instructionId !== "") {
      writer.uint32(18).string(message.instructionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstructionDependency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstructionDependency() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.invocationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.instructionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstructionDependency {
    return {
      invocationId: isSet(object.invocationId) ? globalThis.String(object.invocationId) : "",
      instructionId: isSet(object.instructionId) ? globalThis.String(object.instructionId) : "",
    };
  },

  toJSON(message: InstructionDependency): unknown {
    const obj: any = {};
    if (message.invocationId !== "") {
      obj.invocationId = message.invocationId;
    }
    if (message.instructionId !== "") {
      obj.instructionId = message.instructionId;
    }
    return obj;
  },

  create(base?: DeepPartial<InstructionDependency>): InstructionDependency {
    return InstructionDependency.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<InstructionDependency>): InstructionDependency {
    const message = createBaseInstructionDependency() as any;
    message.invocationId = object.invocationId ?? "";
    message.instructionId = object.instructionId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
