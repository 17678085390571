// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               v5.26.1
// source: go.chromium.org/luci/common/proto/gitiles/gitiles.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Commit, File } from "../git/commit.pb";

export const protobufPackage = "gitiles";

/** LogRequest is request message for Gitiles.Log rpc. */
export interface LogRequest {
  /**
   * Gitiles project, e.g. "chromium/src" part in
   * https://chromium.googlesource.com/chromium/src/+/main
   * Required.
   */
  readonly project: string;
  /**
   * The commit where to start the listing from.
   * The value can be:
   *   - a git revision as 40-char string or its prefix so long as its unique in repo.
   *   - a ref such as "refs/heads/branch"
   *   - a ref defined as n-th parent of R in the form "R~n".
   *     For example, "main~2" or "deadbeef~1".
   * Required.
   */
  readonly committish: string;
  /**
   * If specified, only commits not reachable from this commit (inclusive)
   * will be returned.
   *
   * In git's notation, this is
   *   $ git log ^exclude_ancestors_of committish
   *  OR
   *   $ git log exclude_ancestors_of..committish
   * https://git-scm.com/docs/gitrevisions#gitrevisions-Theememtwo-dotRangeNotation
   *
   * For example, given this repo
   *
   *     base -> A -> B -> C == refs/heads/main
   *        \
   *         X -> Y -> Z  == refs/heads/release
   *
   * calling Log(committish='refs/heads/release',
   *             exclude_ancestors_of='refs/heads/main')
   * will return ['Z', Y', 'X'].
   */
  readonly excludeAncestorsOf: string;
  /** If true, include tree diff in commits. */
  readonly treeDiff: boolean;
  /** If set to a non-empty value, the log will be for the given path */
  readonly path: string;
  /** Value of next_page_token in LogResponse to continue. */
  readonly pageToken: string;
  /** If > 0, number of commits to retrieve. */
  readonly pageSize: number;
}

/** LogRequest is response message for Gitiles.Log rpc. */
export interface LogResponse {
  /** Retrieved commits. */
  readonly log: readonly Commit[];
  /** A page token for next LogRequest to fetch next page of commits. */
  readonly nextPageToken: string;
}

/** RefsRequest is a request message of Gitiles.Refs RPC. */
export interface RefsRequest {
  /**
   * Gitiles project, e.g. "chromium/src" part in
   * https://chromium.googlesource.com/chromium/src/+/main
   * Required.
   */
  readonly project: string;
  /**
   * Limits which refs to resolve to only those matching {refsPath}/*.
   *
   * Must be "refs" or start with "refs/".
   * Must not include glob '*'.
   * Use "refs/heads" to retrieve all branches.
   *
   * To fetch **all** refs in a repo, specify just "refs" but beware of two
   * caveats:
   *  * refs returned include a ref for each patchset for each Gerrit change
   *    associated with the repo.
   *  * returned map will contain special "HEAD" ref whose value in resulting map
   *    will be name of the actual ref to which "HEAD" points, which is typically
   *    "refs/heads/main".
   *
   * Thus, if you are looking for all tags and all branches of repo, it's
   * recommended to issue two Refs calls limited to "refs/tags" and "refs/heads"
   * instead of one call for "refs".
   *
   * Since Gerrit allows per-ref ACLs, it is possible that some refs matching
   * refPrefix would not be present in results because current user isn't granted
   * read permission on them.
   */
  readonly refsPath: string;
}

/** RefsResponse is a response message of Gitiles.Refs RPC. */
export interface RefsResponse {
  /**
   * revisions maps a ref to a revision.
   * Git branches have keys start with "refs/heads/".
   */
  readonly revisions: { [key: string]: string };
}

export interface RefsResponse_RevisionsEntry {
  readonly key: string;
  readonly value: string;
}

/** ArchiveRequest is a request message of the Gitiles.Archive RPC. */
export interface ArchiveRequest {
  /**
   * Gitiles project, e.g. "chromium/src" part in
   * https://chromium.googlesource.com/chromium/src/+/main
   * Required.
   */
  readonly project: string;
  /**
   * The ref at which to generate the project archive for.
   *
   * viz refs/for/branch or just branch
   */
  readonly ref: string;
  /** Format of the returned archive. */
  readonly format: ArchiveRequest_Format;
  /**
   * POSIX style path relative to the project root.
   * Optional. If not specified, it means to get the entire project archive.
   */
  readonly path: string;
}

/**
 * List copied from
 * https://github.com/google/gitiles/blob/65edbe49f2b3882a5979f602383ef0c7b2b8ee0c/java/com/google/gitiles/ArchiveFormat.java
 */
export enum ArchiveRequest_Format {
  Invalid = 0,
  GZIP = 1,
  TAR = 2,
  BZIP2 = 3,
  XZ = 4,
}

export function archiveRequest_FormatFromJSON(object: any): ArchiveRequest_Format {
  switch (object) {
    case 0:
    case "Invalid":
      return ArchiveRequest_Format.Invalid;
    case 1:
    case "GZIP":
      return ArchiveRequest_Format.GZIP;
    case 2:
    case "TAR":
      return ArchiveRequest_Format.TAR;
    case 3:
    case "BZIP2":
      return ArchiveRequest_Format.BZIP2;
    case 4:
    case "XZ":
      return ArchiveRequest_Format.XZ;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ArchiveRequest_Format");
  }
}

export function archiveRequest_FormatToJSON(object: ArchiveRequest_Format): string {
  switch (object) {
    case ArchiveRequest_Format.Invalid:
      return "Invalid";
    case ArchiveRequest_Format.GZIP:
      return "GZIP";
    case ArchiveRequest_Format.TAR:
      return "TAR";
    case ArchiveRequest_Format.BZIP2:
      return "BZIP2";
    case ArchiveRequest_Format.XZ:
      return "XZ";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ArchiveRequest_Format");
  }
}

export interface ArchiveResponse {
  /** Suggested name of the returned archive. */
  readonly filename: string;
  /**
   * Contents of the archive streamed from gitiles.
   *
   * The underlying server RPC streams back the contents. This API simplifies
   * the RPC to a non-streaming response.
   */
  readonly contents: Uint8Array;
}

export interface DownloadFileRequest {
  /**
   * Gitiles project, e.g. "chromium/src" part in
   * https://chromium.googlesource.com/chromium/src/+/main
   * Required.
   */
  readonly project: string;
  /**
   * The commit where to start the listing from.
   * The value can be:
   *   - a git revision as 40-char string or its prefix so long as its unique in repo.
   *   - a ref such as "refs/heads/branch"
   *   - a ref defined as n-th parent of R in the form "R~n".
   *     For example, "main~2" or "deadbeef~1".
   * Required.
   */
  readonly committish: string;
  /** Path relative to the project root to the file to download. */
  readonly path: string;
  /** Format to download file in (default is TEXT). */
  readonly format: DownloadFileRequest_Format;
}

export enum DownloadFileRequest_Format {
  FORMAT_INVALID = 0,
  TEXT = 1,
  JSON = 2,
}

export function downloadFileRequest_FormatFromJSON(object: any): DownloadFileRequest_Format {
  switch (object) {
    case 0:
    case "FORMAT_INVALID":
      return DownloadFileRequest_Format.FORMAT_INVALID;
    case 1:
    case "TEXT":
      return DownloadFileRequest_Format.TEXT;
    case 2:
    case "JSON":
      return DownloadFileRequest_Format.JSON;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DownloadFileRequest_Format");
  }
}

export function downloadFileRequest_FormatToJSON(object: DownloadFileRequest_Format): string {
  switch (object) {
    case DownloadFileRequest_Format.FORMAT_INVALID:
      return "FORMAT_INVALID";
    case DownloadFileRequest_Format.TEXT:
      return "TEXT";
    case DownloadFileRequest_Format.JSON:
      return "JSON";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DownloadFileRequest_Format");
  }
}

export interface DownloadFileResponse {
  /**
   * Decoded contents of the downloaded file.
   *
   * The underlying server RPC streams back the contents. This API simplifies
   * the RPC to a non-streaming response.
   */
  readonly contents: string;
}

export interface DownloadDiffRequest {
  /**
   * Gitiles project, e.g. "chromium/src" part in
   * https://chromium.googlesource.com/chromium/src/+/main
   * Required.
   */
  readonly project: string;
  /**
   * The git revision to get the diff at.
   * The value can be:
   *   - a git revision as 40-char string or its prefix so long as its unique in repo.
   *   - a ref such as "refs/heads/branch"
   *   - a ref defined as n-th parent of R in the form "R~n".
   *     For example, "main~2" or "deadbeef~1".
   * Required.
   */
  readonly committish: string;
  /**
   * The git revision to compute the diff against.
   * The value can be:
   *   - a git revision as 40-char string or its prefix so long as its unique in repo.
   *   - a ref such as "refs/heads/branch"
   *   - a ref defined as n-th parent of R in the form "R~n".
   *     For example, "main~2" or "deadbeef~1".
   * Optional. If not specified, the diff will be against the parent of committish.
   */
  readonly base: string;
  /**
   * Path relative to the project root to the file to limit the diff to.
   * Optional.
   */
  readonly path: string;
}

export interface DownloadDiffResponse {
  /** Decoded contents of the diff. */
  readonly contents: string;
}

export interface ProjectsRequest {
}

export interface ProjectsResponse {
  /** List of available Gitiles projects */
  readonly projects: readonly string[];
}

export interface ListFilesRequest {
  /**
   * Gitiles project, e.g. "chromium/src" part in
   * https://chromium.googlesource.com/chromium/src/+/main
   * Required.
   */
  readonly project: string;
  /**
   * The git revision to list files at.
   * The value can be:
   *   - a git revision as 40-char string or its prefix so long as its unique in repo.
   *   - a ref such as "refs/heads/branch"
   *   - a ref defined as n-th parent of R in the form "R~n".
   *     For example, "main~2" or "deadbeef~1".
   * Required.
   */
  readonly committish: string;
  /**
   * Path relative to the project root to limit the list to. Only direct
   * children will be returned -- the request does not recursively process
   * child directories.
   * Optional.
   */
  readonly path: string;
}

export interface ListFilesResponse {
  /** List of files. */
  readonly files: readonly File[];
}

function createBaseLogRequest(): LogRequest {
  return { project: "", committish: "", excludeAncestorsOf: "", treeDiff: false, path: "", pageToken: "", pageSize: 0 };
}

export const LogRequest = {
  encode(message: LogRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.committish !== "") {
      writer.uint32(26).string(message.committish);
    }
    if (message.excludeAncestorsOf !== "") {
      writer.uint32(18).string(message.excludeAncestorsOf);
    }
    if (message.treeDiff !== false) {
      writer.uint32(32).bool(message.treeDiff);
    }
    if (message.path !== "") {
      writer.uint32(98).string(message.path);
    }
    if (message.pageToken !== "") {
      writer.uint32(82).string(message.pageToken);
    }
    if (message.pageSize !== 0) {
      writer.uint32(88).int32(message.pageSize);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.committish = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.excludeAncestorsOf = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.treeDiff = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.path = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      committish: isSet(object.committish) ? globalThis.String(object.committish) : "",
      excludeAncestorsOf: isSet(object.excludeAncestorsOf) ? globalThis.String(object.excludeAncestorsOf) : "",
      treeDiff: isSet(object.treeDiff) ? globalThis.Boolean(object.treeDiff) : false,
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
    };
  },

  toJSON(message: LogRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.committish !== "") {
      obj.committish = message.committish;
    }
    if (message.excludeAncestorsOf !== "") {
      obj.excludeAncestorsOf = message.excludeAncestorsOf;
    }
    if (message.treeDiff !== false) {
      obj.treeDiff = message.treeDiff;
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    return obj;
  },

  create(base?: DeepPartial<LogRequest>): LogRequest {
    return LogRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LogRequest>): LogRequest {
    const message = createBaseLogRequest() as any;
    message.project = object.project ?? "";
    message.committish = object.committish ?? "";
    message.excludeAncestorsOf = object.excludeAncestorsOf ?? "";
    message.treeDiff = object.treeDiff ?? false;
    message.path = object.path ?? "";
    message.pageToken = object.pageToken ?? "";
    message.pageSize = object.pageSize ?? 0;
    return message;
  },
};

function createBaseLogResponse(): LogResponse {
  return { log: [], nextPageToken: "" };
}

export const LogResponse = {
  encode(message: LogResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.log) {
      Commit.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.log.push(Commit.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogResponse {
    return {
      log: globalThis.Array.isArray(object?.log) ? object.log.map((e: any) => Commit.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: LogResponse): unknown {
    const obj: any = {};
    if (message.log?.length) {
      obj.log = message.log.map((e) => Commit.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<LogResponse>): LogResponse {
    return LogResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LogResponse>): LogResponse {
    const message = createBaseLogResponse() as any;
    message.log = object.log?.map((e) => Commit.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseRefsRequest(): RefsRequest {
  return { project: "", refsPath: "" };
}

export const RefsRequest = {
  encode(message: RefsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.refsPath !== "") {
      writer.uint32(18).string(message.refsPath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.refsPath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefsRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      refsPath: isSet(object.refsPath) ? globalThis.String(object.refsPath) : "",
    };
  },

  toJSON(message: RefsRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.refsPath !== "") {
      obj.refsPath = message.refsPath;
    }
    return obj;
  },

  create(base?: DeepPartial<RefsRequest>): RefsRequest {
    return RefsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RefsRequest>): RefsRequest {
    const message = createBaseRefsRequest() as any;
    message.project = object.project ?? "";
    message.refsPath = object.refsPath ?? "";
    return message;
  },
};

function createBaseRefsResponse(): RefsResponse {
  return { revisions: {} };
}

export const RefsResponse = {
  encode(message: RefsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.revisions).forEach(([key, value]) => {
      RefsResponse_RevisionsEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = RefsResponse_RevisionsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.revisions[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefsResponse {
    return {
      revisions: isObject(object.revisions)
        ? Object.entries(object.revisions).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: RefsResponse): unknown {
    const obj: any = {};
    if (message.revisions) {
      const entries = Object.entries(message.revisions);
      if (entries.length > 0) {
        obj.revisions = {};
        entries.forEach(([k, v]) => {
          obj.revisions[k] = v;
        });
      }
    }
    return obj;
  },

  create(base?: DeepPartial<RefsResponse>): RefsResponse {
    return RefsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RefsResponse>): RefsResponse {
    const message = createBaseRefsResponse() as any;
    message.revisions = Object.entries(object.revisions ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseRefsResponse_RevisionsEntry(): RefsResponse_RevisionsEntry {
  return { key: "", value: "" };
}

export const RefsResponse_RevisionsEntry = {
  encode(message: RefsResponse_RevisionsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefsResponse_RevisionsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefsResponse_RevisionsEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefsResponse_RevisionsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: RefsResponse_RevisionsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create(base?: DeepPartial<RefsResponse_RevisionsEntry>): RefsResponse_RevisionsEntry {
    return RefsResponse_RevisionsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RefsResponse_RevisionsEntry>): RefsResponse_RevisionsEntry {
    const message = createBaseRefsResponse_RevisionsEntry() as any;
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseArchiveRequest(): ArchiveRequest {
  return { project: "", ref: "", format: 0, path: "" };
}

export const ArchiveRequest = {
  encode(message: ArchiveRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.ref !== "") {
      writer.uint32(18).string(message.ref);
    }
    if (message.format !== 0) {
      writer.uint32(24).int32(message.format);
    }
    if (message.path !== "") {
      writer.uint32(34).string(message.path);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArchiveRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArchiveRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ref = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.format = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.path = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArchiveRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      ref: isSet(object.ref) ? globalThis.String(object.ref) : "",
      format: isSet(object.format) ? archiveRequest_FormatFromJSON(object.format) : 0,
      path: isSet(object.path) ? globalThis.String(object.path) : "",
    };
  },

  toJSON(message: ArchiveRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.ref !== "") {
      obj.ref = message.ref;
    }
    if (message.format !== 0) {
      obj.format = archiveRequest_FormatToJSON(message.format);
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    return obj;
  },

  create(base?: DeepPartial<ArchiveRequest>): ArchiveRequest {
    return ArchiveRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ArchiveRequest>): ArchiveRequest {
    const message = createBaseArchiveRequest() as any;
    message.project = object.project ?? "";
    message.ref = object.ref ?? "";
    message.format = object.format ?? 0;
    message.path = object.path ?? "";
    return message;
  },
};

function createBaseArchiveResponse(): ArchiveResponse {
  return { filename: "", contents: new Uint8Array(0) };
}

export const ArchiveResponse = {
  encode(message: ArchiveResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filename !== "") {
      writer.uint32(10).string(message.filename);
    }
    if (message.contents.length !== 0) {
      writer.uint32(18).bytes(message.contents);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArchiveResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArchiveResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filename = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.contents = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArchiveResponse {
    return {
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
      contents: isSet(object.contents) ? bytesFromBase64(object.contents) : new Uint8Array(0),
    };
  },

  toJSON(message: ArchiveResponse): unknown {
    const obj: any = {};
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.contents.length !== 0) {
      obj.contents = base64FromBytes(message.contents);
    }
    return obj;
  },

  create(base?: DeepPartial<ArchiveResponse>): ArchiveResponse {
    return ArchiveResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ArchiveResponse>): ArchiveResponse {
    const message = createBaseArchiveResponse() as any;
    message.filename = object.filename ?? "";
    message.contents = object.contents ?? new Uint8Array(0);
    return message;
  },
};

function createBaseDownloadFileRequest(): DownloadFileRequest {
  return { project: "", committish: "", path: "", format: 0 };
}

export const DownloadFileRequest = {
  encode(message: DownloadFileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.committish !== "") {
      writer.uint32(18).string(message.committish);
    }
    if (message.path !== "") {
      writer.uint32(26).string(message.path);
    }
    if (message.format !== 0) {
      writer.uint32(32).int32(message.format);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DownloadFileRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDownloadFileRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.committish = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.path = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.format = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DownloadFileRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      committish: isSet(object.committish) ? globalThis.String(object.committish) : "",
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      format: isSet(object.format) ? downloadFileRequest_FormatFromJSON(object.format) : 0,
    };
  },

  toJSON(message: DownloadFileRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.committish !== "") {
      obj.committish = message.committish;
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.format !== 0) {
      obj.format = downloadFileRequest_FormatToJSON(message.format);
    }
    return obj;
  },

  create(base?: DeepPartial<DownloadFileRequest>): DownloadFileRequest {
    return DownloadFileRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DownloadFileRequest>): DownloadFileRequest {
    const message = createBaseDownloadFileRequest() as any;
    message.project = object.project ?? "";
    message.committish = object.committish ?? "";
    message.path = object.path ?? "";
    message.format = object.format ?? 0;
    return message;
  },
};

function createBaseDownloadFileResponse(): DownloadFileResponse {
  return { contents: "" };
}

export const DownloadFileResponse = {
  encode(message: DownloadFileResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contents !== "") {
      writer.uint32(10).string(message.contents);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DownloadFileResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDownloadFileResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contents = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DownloadFileResponse {
    return { contents: isSet(object.contents) ? globalThis.String(object.contents) : "" };
  },

  toJSON(message: DownloadFileResponse): unknown {
    const obj: any = {};
    if (message.contents !== "") {
      obj.contents = message.contents;
    }
    return obj;
  },

  create(base?: DeepPartial<DownloadFileResponse>): DownloadFileResponse {
    return DownloadFileResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DownloadFileResponse>): DownloadFileResponse {
    const message = createBaseDownloadFileResponse() as any;
    message.contents = object.contents ?? "";
    return message;
  },
};

function createBaseDownloadDiffRequest(): DownloadDiffRequest {
  return { project: "", committish: "", base: "", path: "" };
}

export const DownloadDiffRequest = {
  encode(message: DownloadDiffRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.committish !== "") {
      writer.uint32(18).string(message.committish);
    }
    if (message.base !== "") {
      writer.uint32(34).string(message.base);
    }
    if (message.path !== "") {
      writer.uint32(26).string(message.path);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DownloadDiffRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDownloadDiffRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.committish = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.base = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.path = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DownloadDiffRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      committish: isSet(object.committish) ? globalThis.String(object.committish) : "",
      base: isSet(object.base) ? globalThis.String(object.base) : "",
      path: isSet(object.path) ? globalThis.String(object.path) : "",
    };
  },

  toJSON(message: DownloadDiffRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.committish !== "") {
      obj.committish = message.committish;
    }
    if (message.base !== "") {
      obj.base = message.base;
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    return obj;
  },

  create(base?: DeepPartial<DownloadDiffRequest>): DownloadDiffRequest {
    return DownloadDiffRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DownloadDiffRequest>): DownloadDiffRequest {
    const message = createBaseDownloadDiffRequest() as any;
    message.project = object.project ?? "";
    message.committish = object.committish ?? "";
    message.base = object.base ?? "";
    message.path = object.path ?? "";
    return message;
  },
};

function createBaseDownloadDiffResponse(): DownloadDiffResponse {
  return { contents: "" };
}

export const DownloadDiffResponse = {
  encode(message: DownloadDiffResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contents !== "") {
      writer.uint32(10).string(message.contents);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DownloadDiffResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDownloadDiffResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contents = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DownloadDiffResponse {
    return { contents: isSet(object.contents) ? globalThis.String(object.contents) : "" };
  },

  toJSON(message: DownloadDiffResponse): unknown {
    const obj: any = {};
    if (message.contents !== "") {
      obj.contents = message.contents;
    }
    return obj;
  },

  create(base?: DeepPartial<DownloadDiffResponse>): DownloadDiffResponse {
    return DownloadDiffResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DownloadDiffResponse>): DownloadDiffResponse {
    const message = createBaseDownloadDiffResponse() as any;
    message.contents = object.contents ?? "";
    return message;
  },
};

function createBaseProjectsRequest(): ProjectsRequest {
  return {};
}

export const ProjectsRequest = {
  encode(_: ProjectsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProjectsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProjectsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ProjectsRequest {
    return {};
  },

  toJSON(_: ProjectsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<ProjectsRequest>): ProjectsRequest {
    return ProjectsRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ProjectsRequest>): ProjectsRequest {
    const message = createBaseProjectsRequest() as any;
    return message;
  },
};

function createBaseProjectsResponse(): ProjectsResponse {
  return { projects: [] };
}

export const ProjectsResponse = {
  encode(message: ProjectsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.projects) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProjectsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProjectsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.projects.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProjectsResponse {
    return {
      projects: globalThis.Array.isArray(object?.projects) ? object.projects.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ProjectsResponse): unknown {
    const obj: any = {};
    if (message.projects?.length) {
      obj.projects = message.projects;
    }
    return obj;
  },

  create(base?: DeepPartial<ProjectsResponse>): ProjectsResponse {
    return ProjectsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ProjectsResponse>): ProjectsResponse {
    const message = createBaseProjectsResponse() as any;
    message.projects = object.projects?.map((e) => e) || [];
    return message;
  },
};

function createBaseListFilesRequest(): ListFilesRequest {
  return { project: "", committish: "", path: "" };
}

export const ListFilesRequest = {
  encode(message: ListFilesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.committish !== "") {
      writer.uint32(18).string(message.committish);
    }
    if (message.path !== "") {
      writer.uint32(26).string(message.path);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFilesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFilesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.committish = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.path = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFilesRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      committish: isSet(object.committish) ? globalThis.String(object.committish) : "",
      path: isSet(object.path) ? globalThis.String(object.path) : "",
    };
  },

  toJSON(message: ListFilesRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.committish !== "") {
      obj.committish = message.committish;
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    return obj;
  },

  create(base?: DeepPartial<ListFilesRequest>): ListFilesRequest {
    return ListFilesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListFilesRequest>): ListFilesRequest {
    const message = createBaseListFilesRequest() as any;
    message.project = object.project ?? "";
    message.committish = object.committish ?? "";
    message.path = object.path ?? "";
    return message;
  },
};

function createBaseListFilesResponse(): ListFilesResponse {
  return { files: [] };
}

export const ListFilesResponse = {
  encode(message: ListFilesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.files) {
      File.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFilesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFilesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.files.push(File.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFilesResponse {
    return { files: globalThis.Array.isArray(object?.files) ? object.files.map((e: any) => File.fromJSON(e)) : [] };
  },

  toJSON(message: ListFilesResponse): unknown {
    const obj: any = {};
    if (message.files?.length) {
      obj.files = message.files.map((e) => File.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<ListFilesResponse>): ListFilesResponse {
    return ListFilesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListFilesResponse>): ListFilesResponse {
    const message = createBaseListFilesResponse() as any;
    message.files = object.files?.map((e) => File.fromPartial(e)) || [];
    return message;
  },
};

export interface Gitiles {
  /** Log retrieves commit log. */
  Log(request: LogRequest): Promise<LogResponse>;
  /** Refs retrieves repo refs. */
  Refs(request: RefsRequest): Promise<RefsResponse>;
  /**
   * Archive retrieves archived content bundle under the provided path in a
   * repo or the entire repo if the path is not provided.
   *
   * Note: for a single file, use DownloadFile to obtain the plain text file.
   */
  Archive(request: ArchiveRequest): Promise<ArchiveResponse>;
  /** DownloadFile retrieves a file from the project. */
  DownloadFile(request: DownloadFileRequest): Promise<DownloadFileResponse>;
  /** DownloadDiff retrives a diff of a revision from the project. */
  DownloadDiff(request: DownloadDiffRequest): Promise<DownloadDiffResponse>;
  /** Projects retrieves list of available Gitiles projects. */
  Projects(request: ProjectsRequest): Promise<ProjectsResponse>;
  /** ListFiles retrieves a list of files at the given revision. */
  ListFiles(request: ListFilesRequest): Promise<ListFilesResponse>;
}

export const GitilesServiceName = "gitiles.Gitiles";
export class GitilesClientImpl implements Gitiles {
  static readonly DEFAULT_SERVICE = GitilesServiceName;
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || GitilesServiceName;
    this.rpc = rpc;
    this.Log = this.Log.bind(this);
    this.Refs = this.Refs.bind(this);
    this.Archive = this.Archive.bind(this);
    this.DownloadFile = this.DownloadFile.bind(this);
    this.DownloadDiff = this.DownloadDiff.bind(this);
    this.Projects = this.Projects.bind(this);
    this.ListFiles = this.ListFiles.bind(this);
  }
  Log(request: LogRequest): Promise<LogResponse> {
    const data = LogRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "Log", data);
    return promise.then((data) => LogResponse.fromJSON(data));
  }

  Refs(request: RefsRequest): Promise<RefsResponse> {
    const data = RefsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "Refs", data);
    return promise.then((data) => RefsResponse.fromJSON(data));
  }

  Archive(request: ArchiveRequest): Promise<ArchiveResponse> {
    const data = ArchiveRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "Archive", data);
    return promise.then((data) => ArchiveResponse.fromJSON(data));
  }

  DownloadFile(request: DownloadFileRequest): Promise<DownloadFileResponse> {
    const data = DownloadFileRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DownloadFile", data);
    return promise.then((data) => DownloadFileResponse.fromJSON(data));
  }

  DownloadDiff(request: DownloadDiffRequest): Promise<DownloadDiffResponse> {
    const data = DownloadDiffRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DownloadDiff", data);
    return promise.then((data) => DownloadDiffResponse.fromJSON(data));
  }

  Projects(request: ProjectsRequest): Promise<ProjectsResponse> {
    const data = ProjectsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "Projects", data);
    return promise.then((data) => ProjectsResponse.fromJSON(data));
  }

  ListFiles(request: ListFilesRequest): Promise<ListFilesResponse> {
    const data = ListFilesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListFiles", data);
    return promise.then((data) => ListFilesResponse.fromJSON(data));
  }
}

interface Rpc {
  request(service: string, method: string, data: unknown): Promise<unknown>;
}

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
